
function init() {
    const filter = document.querySelector('#Form_FilterForm')

    if (!filter) return

    filter.querySelector('select').addEventListener('change', () => {
        filter.submit()
    })
}

init()
