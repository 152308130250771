<template>
    <div class="ingredient-row">
        <div class="ingredient-name">
            <label>Ingredient Name</label>
            <input :name="'Ingredient[' + index + '][name]'" v-model="l_name" type="text" />
        </div>
        <div class="ingredient-q-purchased">
            <label>Quantity Purchased</label>
            <div class="ingredient-combined">
                <input :name="'Ingredient[' + index + '][quantity]'" v-model="p_quantity_purchased" type="text" />
                <select :name="'Ingredient[' + index + '][measurement]'" v-model="l_measurement_purchased">
                    <option value="kilograms">kilograms</option>
                    <option value="grams">grams</option>
                    <option value="mls">mls</option>
                    <option value="tbsp">tbsp</option>
                    <option value="tsp">tsp</option>
                    <option value="litres">litres</option>
                    <option value="cups">cups</option>
                    <option value="each">each</option>
                </select>
            </div>
        </div>
        <div class="ingredient-c-goods">
            <label>Cost of Goods</label>
            <div class="currency">
                <input :name="'Ingredient[' + index + '][cost]'" v-model="l_cost" type="text" />
            </div>
        </div>
        <div class="ingredient-q-used">
            <label>Quantity Used</label>
            <div class="ingredient-combined">
                <input :name="'Ingredient[' + index + '][used]'" v-model="p_quantity_used" type="text" />
                <select :name="'Ingredient[' + index + '][measurement_used]'" v-model="l_measurement_used">
                    <option v-for="(item,index) in measurement_options"
                            v-bind:key="item.value" :value="item.value">{{item.value}}
                    </option>
                </select>
            </div>
        </div>
        <div class="ingredient-t-total">
            <div class="currency">
                <input v-model="l_total" disabled type="text" />
                <input :name="'Ingredient[' + index + '][total]'" v-model="l_total" type="hidden" />
            </div>
        </div>
    </div>
    <!--
    <div class="row ingredient-row" :class="{ 'first': index == 0 }">
        <div class="col-12 sm:col-6">
            <div class="row">
                <div class="col-12 sm:col-6 lg:col-7">
                    <div class="field-group">
                        <div class="field">
                            <label>Ingredient Name</label>
                            <input :name="'Ingredient[' + index + '][name]'" v-model="l_name" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-12 sm:col-6 lg:col-5">
                    <div class="row field-group">
                        <div class="col-6 xs:col-6">
                            <label>Quantity Purchased</label>
                            <div class="field">
                                <input :name="'Ingredient[' + index + '][quantity]'" v-model="p_quantity_purchased" type="text" />
                            </div>
                        </div>
                        <div class="col-6 xs:col-6">
                            <div class="field">
                                <select :name="'Ingredient[' + index + '][measurement]'" v-model="l_measurement_purchased">
                                    <option value="kilograms">kilograms</option>
                                    <option value="grams">grams</option>
                                    <option value="mls">mls</option>
                                    <option value="tbsp">tbsp</option>
                                    <option value="tsp">tsp</option>
                                    <option value="litres">litres</option>
                                    <option value="cups">cups</option>
                                    <option value="each">each</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 sm:col-6">
            <div class="row">
                <div class="col-6 xs:col-3">
                    <div class="field-group">
                        <label>Cost of Goods</label>
                        <div class="field">
                            <div class="currency">
                                <input :name="'Ingredient[' + index + '][cost]'" v-model="l_cost" type="text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 xs:col-3">
                    <div class="field-group">
                        <label>Quantity Used</label>
                        <div class="field">
                            <input :name="'Ingredient[' + index + '][used]'" v-model="p_quantity_used" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-6 xs:col-3">
                    <div class="field">
                        <select :name="'Ingredient[' + index + '][measurement_used]'" v-model="l_measurement_used">
                            <option v-for="(item,index) in measurement_options"
                                    v-bind:key="item.value" :value="item.value">{{item.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6 xs:col-3">
                    <div class="field">
                        <div class="currency">
                            <input v-model="l_total" disabled type="text" />
                            <input :name="'Ingredient[' + index + '][total]'" v-model="l_total" type="hidden" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
</template>

<script>
export default {
    props: {
        index: Number,
        id: Number,
        measurement_purchased: String,
        measurement_used: String,
        quantity_purchased: Number,
        quantity_used: Number,
        cost: [Number, String],
        name: String,
        total: Number
    },
    data() {
        return {
            l_id: this.id,
            l_measurement_purchased: this.measurement_purchased,
            l_measurement_used: this.measurement_used,
            l_quantity_purchased: this.quantity_purchased,
            l_quantity_used: this.quantity_used,
            l_cost: this.cost,
            l_name: this.name,
            measurements: {
                grams: {
                    grams: 1,
                    kilograms: -1000
                },
                kilograms: {
                    kilograms: 1,
                    grams: 1000
                },
                mls: {
                    mls: 1,
                    cups: -250,
                    tbsp: -15,
                    tsp: -5,
                    litres: -1000
                },
                litres: {
                    litres: 1,
                    mls: 1000,
                    cups: 4,
                    tbsp: 66,
                    tsp: 200,
                },
                tbsp: {
                    tbsp: 1,
                    mls: 15,
                    cups: -16.66,
                    tsp: 3,
                    litres: -66
                },
                tsp: {
                    tsp: 1,
                    mls: 5,
                    cups: -50,
                    tbsp: -3,
                    litres: -200
                },
                cups: {
                    cups: 1,
                    mls: 250,
                    tbsp: 16.6,
                    tsp: 50,
                    litres: 0.25
                },
                each: {
                    each: 1
                }
            }
        }
    },
    mounted() {
        // If the values are pre-loaded we should emmit an event
        this.$emit('update', { id: this.id, field: 'total', value: this.l_total })
    },
    computed: {
        measurement_options() {
            let measurements = this.measurements[this.l_measurement_purchased];

            let map = [];
            for (let key of Object.keys(measurements)) {
                map.push({
                    'value': key
                })
            }

            this.l_measurement_used = map[0].value

            return map;
        },
        p_quantity_purchased: {
            set: function(value) {
                let float = parseFloat(value)
                this.l_quantity_purchased = Number.isNaN(float) ? 0 : float
            },
            get: function() {
                return this.l_quantity_purchased
            }
        },
        p_quantity_used: {
            set: function(value) {
                let float = parseFloat(value)
                float = Number.isNaN(float) ? 0 : float
                this.l_quantity_used = float;
                // below for restricting max input on used
                //this.l_quantity_used = (float > this.l_quantity_purchased) ? this.l_quantity_purchased : float
            },
            get: function() {
                return this.l_quantity_used
            }
        },
        l_total() {
            let measurementPurchased = this.l_measurement_purchased;
            let measurementUsed = this.l_measurement_used;
            let conversionRate = this.measurements[measurementPurchased][measurementUsed];

            if(this.cost && this.quantity_used && this.quantity_purchased) {
                let purchased = conversionRate < 0 ? (this.quantity_purchased / (conversionRate * -1)) : (this.quantity_purchased * conversionRate)
                return Math.round((this.cost * (this.quantity_used / purchased)) * 100) / 100;
            }

            return 0;
        }
    },
    // The reason why totals are triggered inline here is because if you have another watcher
    // it will trigger the handler on the parent component to trigger twice.
    watch: {
        l_cost(value) {
            this.$emit('update', { id: this.id, field: 'cost', value })
            this.$emit('update', { id: this.id, field: 'total', value: this.l_total })
        },
        l_quantity_purchased(value) {
            this.$emit('update', { id: this.id, field: 'quantity_purchased', value })
            this.$emit('update', { id: this.id, field: 'total', value: this.l_total })
        },
        l_quantity_used(value) {
            this.$emit('update', { id: this.id, field: 'quantity_used', value })
            this.$emit('update', { id: this.id, field: 'total', value: this.l_total })
        },
        l_total(value) {
            this.$emit('update', { id: this.id, field: 'total', value: this.l_total })
        }
    },
}
</script>
