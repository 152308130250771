
let wakeLock = null

async function requestWakeLock() {
    try {
        wakeLock = await navigator.wakeLock.request();
        wakeLock.addEventListener('release', () => {
            // console.log('Screen Wake Lock released:', wakeLock.released);
        });
        // console.log('Screen Wake Lock released:', wakeLock.released);
    } catch (err) {
        console.error(`${err.name}, ${err.message}`);
    }
};

async function handleVisibilityChange(toggle) {
    if (wakeLock !== null && document.visibilityState === 'visible' && toggle.checked) {
        await requestWakeLock();
    }
};


function setup(toggle) {

    document.addEventListener('visibilitychange', () => {
        handleVisibilityChange(toggle)
    });

    if (!navigator.wakeLock) {
        console.warn('Wakelock is unavailable. This may be due to an insecure context. https://developer.mozilla.org/en-US/docs/Web/API/WakeLock')
    }

    toggle.addEventListener('change', async () => {

        if (toggle.checked) {
            await requestWakeLock()
        } else {
            wakeLock.release()
            wakeLock = null
        }
    })
}

function init() {

    const toggles = Array.from(document.querySelectorAll('[data-keep-awake]'))

    if (!toggles) return

    toggles.forEach(setup)
}

init()
