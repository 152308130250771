<template>
    <div class="container">
        <div class="row">
            <div class="col-12 sm:col-6">
                <h2>Recipe</h2>
                <div class="row">
                    <div class="col-6">
                        <div class="field">
                            <label>Venue Name</label>
                            <input id="VenueName" name="VenueName" type="text" required />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="field">
                            <label>Recipe Name</label>
                            <input id="RecipeName" name="RecipeName" type="text" required />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="field-group">
                            <div class="field">
                                <label>Number of servings</label>
                                <input id="Servings" name="Servings" type="number" required v-model="servings" />
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="field">
                            <label class="label">Price per serving</label>
                            <div class="currency">
                                <input id="ServePrice" name="ServePrice" type="text" required v-model="serving_cost" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 sm:col-6">
                <h2>Method</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="field">
                            <label>Add your method</label>
                            <textarea id="Method" name="Method" rows="5"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Ingredients</h2>
            </div>
        </div>
        <div class="ingredients">
            <Ingredient
                v-for="(ingredient, index) in ingredients"
                :key="index"
                :index="index"
                :id="ingredient.id"
                :name="ingredient.name"
                :measurement_purchased="ingredient.measurement_purchased"
                :measurement_used="ingredient.measurement_used"
                :quantity_purchased="ingredient.quantity_purchased"
                :quantity_used="ingredient.quantity_used"
                :cost="ingredient.cost"
                :total="ingredient.total"
                @update="updateIngredient"
            ></Ingredient>
        </div>
        <div class="ingredients-actions">
            <a class="button-extra" @click="addIngredient">Add Ingredient</a>
        </div>
        <div class="ingredients-totals-wrapper">
            <div class="container">
                <div class="ingredients-totals__container">
                    <div class="ingredients-totals__title">
                        Summary
                    </div>
                    <div class="ingredients-totals__content">
                        <div class="ingredient-total-group">
                            <div class="ingredient-total">
                                <label>Total Batch Cost</label>
                                <div class="currency">
                                    <input v-model="total_batch_cost" type="text" disabled />
                                    <input id="TotalCost" name="TotalCost" v-model="total_batch_cost" type="hidden" />
                                </div>
                            </div>
                            <div class="ingredient-total">
                                <label>(Inc GST)</label>
                                <div class="currency">
                                    <input v-model="with_gst" type="text" disabled />
                                    <input id="TotalCostGst" name="TotalCostGst" v-model="with_gst" type="hidden" />
                                </div>
                            </div>
                        </div>
                        <div class="ingredient-total-group">
                            <div class="ingredient-total">
                                <label>Cost Per Serve</label>
                                <div class="currency">
                                    <input v-model="total_per_serve" type="text" disabled />
                                    <input id="TotalPerServe" name="TotalPerServe" v-model="total_per_serve" type="hidden" />
                                </div>
                            </div>
                            <div class="ingredient-total">
                                <label>Total Sales</label>
                                <div class="currency">
                                    <input v-model="total_sales_amout" type="text" disabled />
                                    <input id="TotalSales" name="TotalSales" v-model="total_sales_amout" type="hidden" />
                                </div>
                            </div>
                        </div>
                        <div class="ingredient-total-group">
                            <div class="ingredient-total">
                                <label>Gross Profit %</label>
                                <div>
                                    <input v-model="p_food_gross_profit" type="text" disabled />
                                    <input id="GrossProfit" name="GrossProfit" v-model="p_food_gross_profit" type="hidden" />
                                </div>
                            </div>
                            <div class="ingredient-total">
                                <label>Markup %</label>
                                <div>
                                    <input v-model="p_food_markup" type="text" disabled />
                                    <input id="FoodMarkup" name="FoodMarkup" v-model="p_food_markup" type="hidden" />
                                </div>
                            </div>
                        </div>
                        <div class="ingredient-total">
                            <label>Total Generated Profit</label>
                            <div class="currency">
                                <input v-model="total_profit" type="text" disabled />
                                <input id="TotalProfit" name="TotalProfit" v-model="total_profit" type="hidden" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ingredient-download" v-if="complete">
            <button type="submit" class="btn btn-primary">Download PDF</button>
        </div>
    </div>
</template>

<script>
import Ingredient from './Ingredient.vue'

const BaseIngredient = {
    name: undefined,
    quantity_purchased: 0,
    measurement_purchased: 'grams',
    cost: 0,
    quantity_used: 0,
    total: 0
}

export default {
    components: {
        Ingredient
    },
    data() {
        return {
            serving_cost: 0,
            servings: 0,
            total_batch_cost: 0,
            with_gst: 0,
            total_per_serve: 0,
            total_sales_amout: 0,
            food_gross_profit: 0,
            food_markup: 0,
            total_profit: 0,
            ingredients: [
                {
                    id: 0,
                    quantity_purchased: 0,
                    quantity_used: 0,
                    cost: 0,
                    measurement_purchased: 'grams',
                    measurement_used: 'grams'
                },
                {
                    id: 1,
                    quantity_purchased: 0,
                    quantity_used: 0,
                    cost: 0,
                    measurement_purchased: 'grams',
                    measurement_used: 'grams'
                }
            ]
        }
    },
    computed: {
        complete() {
            return this.total_profit != 0;
        },
        p_food_gross_profit() {
            return this.food_gross_profit + '%'
        },
        p_food_markup() {
            return this.food_markup + '%'
        },
    },
    watch: {
        servings: {
            handler(value) {
                this.updateFields();
            }
        },
        serving_cost: {
            handler(value) {
                this.updateFields();
            }
        },
        ingredients: {
            deep: true,
            handler(value) {
                this.updateFields();
            }
        }
    },
    methods: {
        updateFields() {
            this.total_batch_cost = this.getTotalbatchCost()
            this.with_gst = this.getWithGst()
            this.total_per_serve = this.getTotalPerServe()
            this.total_sales_amout = this.getSellingPricePerServe()
            this.food_gross_profit = this.getFoodGrossProfit()
            this.food_markup = this.getFoodMarkup()
            this.total_profit = this.getTotalProfit()
        },
        getTotalbatchCost() {
            const totals = this.ingredients.map(ingredient => ingredient.total)
            return Math.round(totals.reduce((accumulator, current) => accumulator + current) * 100) / 100
        },
        getWithGst() {
            return Math.round((this.total_batch_cost * 1.15) * 100) / 100
        },
        getTotalPerServe() {
            return !this.servings ? 0 : parseFloat((this.total_batch_cost / this.servings).toFixed(2))
        },
        getSellingPricePerServe() {
            return this.servings * this.serving_cost
        },
        getFoodGrossProfit() {
            if(this.total_sales_amout && this.total_batch_cost) {
                return parseFloat((((this.total_sales_amout - this.total_batch_cost) /  this.total_sales_amout) * 100).toFixed(2))
            }

            return 0;
        },
        getFoodMarkup() {
            if(this.total_sales_amout && this.total_batch_cost) {
                return parseFloat((((this.total_sales_amout - this.total_batch_cost) /  this.total_batch_cost) * 100).toFixed(2))
            }

            return 0;
        },
        getTotalProfit() {
            if(this.total_sales_amout && this.total_batch_cost) {
                return parseFloat(this.total_sales_amout - this.total_batch_cost).toFixed(2)
            }

            return 0;
        },
        addIngredient(data={}) {
            const clone = [...this.ingredients]
            const I = { ...BaseIngredient, ...data }
            I.id = clone.length
            clone.push(I)
            this.ingredients = clone
        },
        updateIngredient(data) {
            const { id, field, value } = data

            this.ingredients = this.ingredients.map(ingredient => {

                if(ingredient.id === id) {
                    ingredient[field] = value
                }

                return ingredient
            })
        },
    }
}
</script>
