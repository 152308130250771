
function init() {

    const modal = document.querySelector('.video-modal-dialog')

    if (!modal) return

    const container = document.querySelector('.video-content')
    const button = container.querySelector('.video-content__background')


    const close = modal.querySelector('.video-modal__close')

    button.addEventListener('click', () => {
        modal.showModal()
    })

    close.addEventListener('click', () => {
        modal.close()
    })
}

init()
