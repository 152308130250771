const activeClass = 'header-mega--active'
const timeout = 500
let intentTimer = null

function setup(trigger) {

    const menu = document.querySelector(`[data-mega-menu="${trigger.dataset.megaTrigger}"]`)
    const menus = Array.from(document.querySelectorAll('[data-mega-menu]')).filter(m => {
        return m !== menu
    })

    if (!menu) {
        console.warn(`mega menu doesn't exist for ${trigger}`)
    }

    trigger.addEventListener('mouseenter', () => {
        intentTimer = null
        menu.classList.add(activeClass)

        menus.forEach(m => {
            m.classList.remove(activeClass)
        })
    })

    menu.addEventListener('mouseenter', () => {
        intentTimer = null
    })

    trigger.addEventListener('mouseleave', () => {
        intentTimer = setTimeout(() => {
            if (intentTimer != null) {
                menu.classList.remove(activeClass)
            }
        }, timeout)
    })

    menu.addEventListener('mouseleave', () => {
        intentTimer = setTimeout(() => {
            if (intentTimer != null) {
                menu.classList.remove(activeClass)
            }
        }, timeout)
    })
}

function init() {
    const triggers = Array.from(document.querySelectorAll('[data-mega-trigger]'))

    if (!triggers.length) {
        return
    }

    triggers.forEach(setup)
}

init()
